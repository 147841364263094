import paper from 'paper'
import * as utils from '../utilities/Utils'
import * as styles from '../styles/paperStyles'
import {useStore} from '@/store/store'
import {runModel} from '../compute/modelUtil.js'
import * as modifiers from "@/utilities/Modifiers.js"
import {gradientColors} from '@/constants/gradient.js'
export class AnalyzeMode{
  store = useStore()
  constructor(){
    this.analyzeMode = false
    this.results = null
    this.warning = null
    this.okayToRunWarning = false
    this.finishedComputing = false
    this.maxValues = null
    this.scalars = null
  }
  async runAnalysis() {
    //Make sure correct layers are visible
    if (this.store.showLoading == false){
      this.store.loadLayer.visible = false
    }
    if (this.store.showReactions == false){
      this.store.reactionLayer.visible = false
    }
    if (this.store.showModeShape){
      this.store.modeLayer.visible = true
      this.store.drawingLayer.visible = false
      this.store.memberSizesLayer.visible = false
      this.store.nodeLayer.visible = false
    }
    this.store.setShowValues(this.store.showValues)

    //Create Shape Diver Inputs
    var inputs = this.createSDInputData()
    console.log(inputs)

    //if input string longer than 10,000 need to split into parts so it can be handled by shapediver
    var stringInputs = JSON.stringify(inputs);
    console.log('stringInput', stringInputs)
    var strings = ["", "", "", "", "", ""]
    const maxChars = 10000
    let reqNumStringInputs = Math.ceil(stringInputs.length/10000)
    if (reqNumStringInputs > 5){
      this.warning = "Model Too Large To Compute"
      this.finishedComputing = true
      return
    }
    if (stringInputs.includes("null")){
      this.warning = "Unable to Compute"
      this.finishedComputing = true
      return
    }

    for (let i = 0; i < reqNumStringInputs; i++){
      if (i == Math.ceil(stringInputs.length/maxChars)){
        strings[i] = stringInputs.slice(i*maxChars)
      }
      else {
        strings[i] = stringInputs.slice(i*maxChars, (i+1)*maxChars)
      }
    }
    var data = {
      text1: strings[0],
      text2: strings[1],
      text3: strings[2],
      text4: strings[3],
      text5: strings[4]
    }

    //Run Model
    try {
      var results = await runModel(data)
      console.log('results', results)
    } catch (error) {
      this.warning = error.message
      this.finishedComputing = true
      return
    }
 
    //log any karamba warnings
    if (results.messages.warning != "na"){
      let message = results.messages.warning.split("Try")[0]
      this.warning = message
      this.finishedComputing = true
    }
    
    try{
      this.results = results

      //Get Max Values - Temp Code To Fix Work Around Shapediver Issue
      let maxValues = {
        axial: 0,
        deflectionX: 0,
        deflectionY: 0,
        shear: 0,
        moment: 0,
        utilization: 0
      }
      results.elements.forEach(element => {
        Object.keys(element).forEach(key => {
          if (key == 'utilization'){
            maxValues.utilization = element[key]
          }
          else if (key != 'id'){
            let values = JSON.parse(element[key])
            const maxAbsValue = Math.max(...values.map(num => Math.abs(num)));
            maxValues[key] = maxAbsValue
          }
        })
      })
      this.maxValues = maxValues
      this.store.minigameDeflection = utils.deflectionConvertFrom(Math.max(this.maxValues.deflectionY, this.maxValues.deflectionX))
      this.store.minigameUtilization = this.maxValues.utilization * 100 
  
      //draw reactions
      var reactions = results.reactions  
      //get max reaction forces 
      var maxX = 0
      var maxY = 0
      var minX = Infinity
      var minY = Infinity
      var maxReactionScale = 10
      reactions.forEach(reaction => {
        var forceX = Math.abs(JSON.parse(reaction.force)[0])
        var forceY = Math.abs(JSON.parse(reaction.force)[1])
        if (forceX > maxX){
          maxX = forceX
        }
        if (forceX < minX){
          minX = forceX
        }
        if (forceY > maxY){
          maxY = forceY
        }
        if (forceY > minY){
          minY = forceY
        }
      })
      var scaleX = ((maxX-minX)/maxX)*maxReactionScale
      if (scaleX < 2.5){scaleX = 2.5}
      var scaleY = ((maxY-minY)/maxY)*maxReactionScale
      if (scaleY < 2.5){scaleY = 2.5}
  
      reactions.forEach((reaction) => {
        var force = JSON.parse(reaction.force)
        var id = reaction.id ? JSON.parse(reaction.id) : null
        var correspondingSupport = this.store.supportLayer.children.find(support => support._id == id)
        if (correspondingSupport){
          var supportLocation = correspondingSupport.bounds.bottomCenter
          let location = {x: supportLocation.x, y: supportLocation.y} 
          force[0] = force[0]*-1
          force[1] = force[1]
          var textContent = ''
          var vertArrowHeight
          let reactionGroup = new paper.Group()
          // Vertical Reaction
          if (force[1] != 0){
            var vertArrow = this.store.tools.loads.newArrow()
            if (force[1] > 0){
              vertArrow.rotate(180)
            }
            var scaleValue = Math.abs(force[1]/maxY)*scaleY
            if (scaleValue < 1){scaleValue = 1}
            vertArrow.scale(scaleValue/this.store.grid.canvasZoom)
            vertArrow.setPosition(location)
            vertArrow.translate({x:0, y: vertArrow.bounds.height/2})
            vertArrowHeight = vertArrow.bounds.height
            vertArrow.style = styles.reaction()
            reactionGroup.addChild(vertArrow)
            let convertedForce = utils.forceConvertFrom(force[1])
            textContent += `Ry: ${utils.Round(convertedForce, 2)}`
          }
          //Horizontal Reaction
          if (force[0] != 0){
            var horzArrow = this.store.tools.loads.newArrow()
            if (force[0] > 0){
              horzArrow.rotate(90)
            }
            else {
              horzArrow.rotate(270)
            }
            var scaleValue = Math.abs(force[0]/maxX)*scaleY
            if (scaleValue < 1){scaleValue = 1}
            horzArrow.scale(scaleValue, horzArrow.bounds.center)
            horzArrow.setPosition(location)
            var offsetHeight
            if (!vertArrowHeight){ offsetHeight = 50/this.store.grid.canvasZoom}
            else {offsetHeight = vertArrowHeight/2}
            horzArrow.translate({x:0, y: offsetHeight})
            horzArrow.style = styles.reaction()
            reactionGroup.addChild(horzArrow)
            if (textContent.length != 0){
              textContent += '\n'
            }
            let convertedForce = utils.forceConvertFrom(force[0])
            textContent += `Rx: ${utils.Round(convertedForce*-1, 2)}`
          }
          //Moment
          if (reaction.moment != 0){
            var circle = new paper.Path.Circle({center: location, radius: 8/this.store.grid.canvasZoom})
            var offsetHeight
            if (!vertArrowHeight){ offsetHeight = 50/this.store.grid.canvasZoom}
            else {offsetHeight = vertArrowHeight/2}
            circle.translate({x:0, y: offsetHeight})
            circle.style = styles.reaction()
            reactionGroup.addChild(circle)
            if (textContent.length != 0){
              textContent += '\n'
            }
            var convertedMoment = utils.momentConvertFrom(reaction.moment)
            textContent += `M: ${utils.Round(convertedMoment, 2)}`
          }
          var reactionText = new paper.PointText(location)
          var offsetHeight
          if (!vertArrowHeight){offsetHeight = 70/this.store.grid.canvasZoom}
          else {offsetHeight = vertArrowHeight + 10/this.store.grid.canvasZoom}
          reactionText.translate({x:0, y: offsetHeight})
          reactionText.style = styles.reactionText
          reactionText.content = textContent
          reactionText.scale(1/this.store.grid.canvasZoom)
          reactionGroup.addChild(reactionText)
          reactionGroup.data.location = location
          reactionGroup.data.supportId = id
          this.store.reactionLayer.addChild(reactionGroup)
        }
      })
  
      //Draw Results
      var elements = this.store.drawingLayer.children
      var currentMode = results.modes[this.store.mode-1]
      this.store.frequency = currentMode.frequency
      this.store.period = currentMode.period
      this.scalars = this.calcScalingValues()
  
      for (let i = 0; i < elements.length; i++){
        //find shapediver result that matches the current element being looked at
        var elementId = elements[i].id
        var elemProps = this.getElemProps(elementId)
        var modeProps = currentMode.elements.find(element => element.id == elementId)
        var relValues = this.getMinMaxValues(elemProps)
  
        //create new paths
        var paths = {}
        for (const path in styles.analysisPaths){
          var style = styles.analysisPaths[path]
          paths[path] = new paper.Path(style)
          paths[path].strokeWidth = style.strokeWidth/this.store.grid.canvasZoom
        }
  
        var [leftPoint, rightPoint] = utils.reorientLine(elements[i])
        var elementReoriented = new paper.Path([leftPoint, rightPoint])
        var normal = elementReoriented.getNormalAt(0)
        
        elements[i].data.normal = normal
  
        //draw analysis results
        var location = 0
        for (let j = 0; j<11; j++){
          var pointOnCurve = elementReoriented.getLocationAt(location*elementReoriented.length)
          if (j == 0){
            this.addPointToPath(paths, pointOnCurve)
          }
          this.drawForce(['moment', 'shear', 'axial'], elemProps, j, paths, pointOnCurve, normal, relValues)
          this.drawdeflection(elemProps, j, paths.deflection, pointOnCurve, relValues)
          this.drawModeShape(modeProps, j, paths.mode, pointOnCurve)
          if (j == 10){
            this.addPointToPath(paths, pointOnCurve)
          }
          location += 0.1
        }
        paths.mode.smooth({ type: 'continuous' })
        this.drawUtilization(elements[i], elemProps, paths.utilization)
  
        for (const layer in this.store.analysisLayers){
          this.store.analysisLayers[layer].addChild(paths[layer])
        }
        elementReoriented.remove()
      }

      this.finishedComputing = true
      return true
    }catch(error){
      console.log('unable to compute:',  error)
      this.warning = "Unable to Compute"
      this.finishedComputing = true
      return false
    }
  }
  createSDInputData(){
    var lines = []
    var supports = []
    var pointLoads = []
    var lineLoads = []
    var lineMassLoads = []

    //find left bottom most point in drawing layer
    //use this point to determine element lengths when grid size != 1
    var origin = this.store.drawingLayer.bounds.bottomLeft
    this.origin = origin
    
    let totWeight = 0
    
    //Frame Elements
    for (let i = 0; i<this.store.drawingLayer.children.length; i++){
      let line = this.store.drawingLayer.children[i]
      var lineClone = line.clone()
      var [start, end] = utils.reorientLine(lineClone)
      start.x = utils.lengthConvertTo(start.x)
      start.y = utils.lengthConvertTo(start.y)
      end.x = utils.lengthConvertTo(end.x)
      end.y = utils.lengthConvertTo(end.y)
      
      //Convert Section Size if Karamba doesn't know what it is in its given units
      let section
      let customSectionProps = ""
      if (line.data.frameProps.size.userId){
        let sectionProps = line.data.frameProps.size
        let sectionPropsToNotInclude = ['name', 'sectionType', 'userId', 'id', 'creator']

        let sectionPropsForKaramba = {}
        for (let prop in sectionProps){
          if (!sectionPropsToNotInclude.includes(prop)){
            if (prop == 'Ixx' || prop == 'Iyy'){
              sectionPropsForKaramba[prop] = utils.inertiaConvertTo(sectionProps[prop].value, sectionProps[prop].unit)
            }
            else if (prop == 'area'){
              sectionPropsForKaramba[prop] = utils.areaConvertTo(sectionProps[prop].value, sectionProps[prop].unit)
            } 
            else if (prop == 'material'){ 
              if (sectionProps[prop].metric){
                let material = sectionProps[prop].metric
                sectionPropsForKaramba.E = utils.EconvertTo(material.E.value, material.E.unit)
                sectionPropsForKaramba.density = material.density.value,
                sectionPropsForKaramba.yieldStress = utils.stressConvertTo(material.yieldStress.value, material.yieldStress.unit)
                // sectionPropsForKaramba.tensileStrength = utils.stressConvertTo(material.tensileStrength.value, material.tensileStrength.unit)
                // sectionPropsForKaramba.compressiveStrength = -utils.stressConvertTo(material.compressiveStrength.value, material.compressiveStrength.unit)
                sectionPropsForKaramba.materialName = material.name
              } 
              else {
                sectionPropsForKaramba.E = utils.EconvertTo(sectionProps[prop].E.value, sectionProps[prop].E.unit)
                sectionPropsForKaramba.density = utils.densityConvertTo(sectionProps[prop].E.value, sectionProps[prop].E.unit)
                sectionPropsForKaramba.yieldStress = utils.stressConvertTo(sectionProps[prop].yieldStress.value, sectionProps[prop].yieldStress.unit)
                // sectionPropsForKaramba.tensileStrength = utils.stressConvertTo(sectionProps[prop].tensileStrength.value, sectionProps[prop].tensileStrength.unit)
                // sectionPropsForKaramba.compressiveStrength = -utils.stressConvertTo(sectionProps[prop].compressiveStrength.value, sectionProps[prop].compressiveStrength.unit)
                sectionPropsForKaramba.materialName = sectionProps[prop].name
              } 
            }
            else {
              sectionPropsForKaramba[prop] = utils.sectionGeomConvertTo(sectionProps[prop].value, sectionProps[prop].unit)
            }
          }
        }
        customSectionProps = sectionPropsForKaramba
        let type = sectionProps.sectionType == 'Custom' ? 'Custom*' : sectionProps.sectionType
        section = `${type}_${line._id}` 
      }
      else {
        let convertedSection = utils.tryConvertSectionSizeForKaramba(line.data.frameProps.size)
        if (convertedSection) section = convertedSection.name
        else section = line.data.frameProps.size.name
      }

      var lineProps = {
        id: line.id,
        start: [start.x/30, 0, -start.y/30],
        end: [end.x/30, 0, -end.y/30],
        startFixity: lineClone.data.start,
        endFixity: lineClone.data.end,
        section: section,
        customSectionProps: customSectionProps,
        orientation: line.data.frameProps.orientation
      }
      lineClone.remove()
      lines.push(lineProps)
      
      //Calc Weight
      let frameWeight = utils.calcWeight(line)
      totWeight += frameWeight
    }
    this.store.minigameWeight = totWeight

    //supports
    for (let i=0; i< this.store.supportLayer.children.length; i++){
      var support = this.store.supportLayer.children[i]
      var supportLocation = {x: support.data.location.x, y: support.data.location.y}
      supportLocation.x = utils.lengthConvertTo(supportLocation.x)
      supportLocation.y = utils.lengthConvertTo(supportLocation.y)

      var supportProps = {
        type: support.data.type,
        location: [
          supportLocation.x/30, 
          0, 
          -supportLocation.y/30
        ],
        id: support.id
      }
      supports.push(supportProps)
    }

    //Loads
    var pointL = this.store.loadLayer.children.filter((load) => load.data.type == 'Point Load' && load.data.location)
    var lineL = this.store.loadLayer.children.filter((load) => load.data.type == 'Line Load' && load.data.elementId)
    var lineMass = this.store.loadLayer.children.filter((load) => load.data.type == 'Line Mass' && load.data.elementId)
    //Point Loads
    for (let i=0; i< pointL.length; i++){
      var pointLocation = {x: pointL[i].data.location.x, y: pointL[i].data.location.y}
      pointLocation.x = utils.lengthConvertTo(pointLocation.x)
      pointLocation.y = utils.lengthConvertTo(pointLocation.y)

      let magnitude = utils.forceConvertTo(pointL[i].data.magnitude)
      let loadDirection = pointL[i].data.dir
      let vector = this.getLoadVector(loadDirection, magnitude)

      var pointLoadProps = {
        type: pointL[i].data.type,
        location: [
          pointLocation.x/30, 
          0, 
          -pointLocation.y/30
        ],
        vector: vector
      }
      pointLoads.push(pointLoadProps)
    }

    //Line Loads
    for (let i=0; i< lineL.length; i++){
      let magnitude = utils.distributedLoadConvertTo(lineL[i].data.magnitude)
      let loadDirection = lineL[i].data.dir
      let vector = this.getLoadVector(loadDirection, magnitude)

      var lineLoadProps = {
        type: lineL[i].data.type,
        elementId: lineL[i].data.elementId,
        vector: vector
      }
      lineLoads.push(lineLoadProps)
    }
    //Line Mass 
    for (let i=0; i<lineMass.length; i++){
      let magnitude = utils.distributedLoadConvertTo(lineMass[i].data.magnitude)
      var lineLoadProps = {
        type: 'Line Mass',
        elementId: lineMass[i].data.elementId,
        magnitude: magnitude
      }
      lineMassLoads.push(lineLoadProps)
    }
    var inputs = {
      gravity: this.store.gravity,
      lines: lines,
      supports: supports,
      pointLoads: pointLoads,
      lineLoads: lineLoads,
      lineMassLoads: lineMassLoads
    }
    return inputs
  }
  getMinMaxValues(elemProps){
    var elementValues = []
    for (const prop in elemProps){
      var relevantValues = []
      if (!(prop == 'id' || prop == 'utilization')){
        let values = JSON.parse(elemProps[prop])
        let min = Math.min(...values)
        let max = Math.max(...values)
        var plotMiddle = false
        if (min == max && min != 0){
          plotMiddle = true
        }
        if (min != 0){
          relevantValues.push(min)
        }
        if (max != 0){
          relevantValues.push(max)
        }
        relevantValues = [...new Set(relevantValues)]
        elementValues[prop] = {values: relevantValues, plotMiddle: plotMiddle}
      }
    }
    return elementValues
  }
  getLoadVector(loadDirection, magnitude){
    let vector
    switch (loadDirection){
      case 'Down':
        vector = `{0, 0, -${magnitude}}`
        break;
      case 'Left': 
        vector = `{-${magnitude}, 0, 0}`
        break;
      case 'Right':
        vector = `{${magnitude}, 0, 0}`
        break;
      case 'Up':
        vector = `{0, 0, ${magnitude}}`
        break;
    }
    return vector
  }
  calcScalingValues(){
    //Remapping Equation: y = log(x+1), @x=0 => y=0, @x=100 => y=4.61612, @x=1000 => y=6.908754, @x=100 => y=9.21044, 

    var scalars = {}
    //shear, axial, moment
    var maxValues = this.maxValues
    for (const key in maxValues){
      if (!key.includes("deflection")){
        var maxValue
        if (key.includes("moment")){
            maxValue = utils.momentConvertFrom(maxValues[key])
        }else{
            maxValue = utils.forceConvertFrom(maxValues[key])
        }
        var denominator
        var maxLogValue
        if (maxValue < 10){
          denominator = 2.39789
          maxLogValue = 20
        }
        else if (maxValue < 100){
          denominator = 4.61612
          maxLogValue = 2
        }
        else if (maxValue < 1000){
          denominator=6.908754
          maxLogValue = 0.2
        }
        else if (maxValue < 10000) {
          denominator = 9.21044
          maxLogValue = 0.02
        }
        else {
          denominator = 11.512935
          maxLogValue = 0.002
        }
        var remapped = Math.log(maxValue+1)
        var normalizedValue = (remapped/denominator)*maxLogValue
        scalars[key] = normalizedValue
      }
    }
    //deflection
    var deflectionX = utils.lengthConvertFrom(this.maxValues.deflectionX)
    var deflectionY = utils.lengthConvertFrom(this.maxValues.deflectionY)
    var maxDeflection = Math.max(deflectionX, deflectionY)
    var maxLogValue
    var denominator
    if (maxDeflection < 0.001){
        denominator = 0.000999
        maxLogValue = 8000
    }
    else if (maxDeflection < 0.01){
        denominator = 0.0099503
        maxLogValue = 2000
    }
    else if (maxDeflection < 0.1){
        denominator = 0.0953101
        maxLogValue = 400
    }
    else if (maxDeflection < 1){
        denominator = 0.69314718
        maxLogValue = 40
    }
    else {
        denominator = 2.39789
        maxLogValue = 4
    }
    var remapped = Math.log(maxDeflection+1)
    var normalizedValue = (remapped/denominator)*maxLogValue
    scalars.deflection = normalizedValue

    return scalars
  }
  drawForce(forces, elemProps, j, paths, pointOnCurve, normal, relevantValues){
    forces.forEach(force => {
      var relValues = relevantValues[force].values
      var karambaValue = JSON.parse(elemProps[force])[j]
      var convertedValue
      if (force != 'moment'){
        convertedValue = utils.forceConvertFrom(karambaValue)
      }
      else {
        convertedValue = utils.momentConvertFrom(karambaValue)
      }
      var scaledValue = convertedValue*this.store.analysisScales[force]*this.scalars[force]
      var path = paths[force]
      var valuesLayer = this.store.analysisValuesLayers[force]
      var style = styles.analysisTextStyles[force]
      var plotMiddle = relevantValues[force].plotMiddle
      var point = new paper.Point(
        pointOnCurve.point.x+normal.x*scaledValue, 
        pointOnCurve.point.y+normal.y*scaledValue
      )
      path.add(point)
      if (force == "axial" && karambaValue > 0){
        path.strokeColor
        path.fillColor
        path.style = styles.axialTension
      }
      if ((relValues.includes(karambaValue) && !plotMiddle) || 
          (relValues.includes(karambaValue) && plotMiddle && j == 6)){
        var text = new paper.PointText(point)
        text.content = utils.Round(convertedValue, 3).toString()
        if (force == "axial" && karambaValue > 0){
          text.style = styles.axialTensionText
        }
        else {
          text.style=style
        }
        text.scale(1/this.store.grid.canvasZoom)
        valuesLayer.addChild(text)
      }
    });
  }
  drawdeflection(elemProps, j, path, pointOnCurve, relevantValues){
    var relValuesX = relevantValues.deflectionX.values
    var relValuesY = relevantValues.deflectionY.values
    var karambaValueX = JSON.parse(elemProps.deflectionX)[j]
    var karambaValueY = JSON.parse(elemProps.deflectionY)[j]
    var convertedValueX = utils.deflectionConvertFrom(karambaValueX)
    var convertedValueY = utils.deflectionConvertFrom(karambaValueY)
    var deflectionXScaled = karambaValueX*this.store.analysisScales.deflection*this.scalars.deflection
    var deflectionYScaled =  karambaValueY*this.store.analysisScales.deflection*this.scalars.deflection
    var deflectionPoint = new paper.Point(
      pointOnCurve.point.x+deflectionXScaled, 
      pointOnCurve.point.y-deflectionYScaled
    )
    //var plotMiddle = (relevantValues.deflectionX.plotMiddle || relevantValues.deflectionY.plotMiddle)
    var plotMiddleX = relevantValues.deflectionX.plotMiddle 
    var plotMiddleY = relevantValues.deflectionY.plotMiddle
    path.add(deflectionPoint)
    if (
        ((relValuesY.includes(karambaValueY) && !plotMiddleY) ||
        (relValuesY.includes(karambaValueY) && plotMiddleY) && j==6 ||
        (relValuesX.includes(karambaValueX) && !plotMiddleX) ||
        (relValuesX.includes(karambaValueX) && plotMiddleX) && j==6)
      ){
      var text = new paper.PointText(deflectionPoint)
      text.content = `(${utils.Round(convertedValueX, 4).toString()},${utils.Round(convertedValueY, 4).toString()})`
      text.style = styles.analysisTextStyles.deflection
      text.scale(1/this.store.grid.canvasZoom)
      this.store.analysisValuesLayers.deflection.addChild(text)
    }
  }
  drawModeShape(modeProps, j, path, pointOnCurve){
    var karambaValueX = JSON.parse(modeProps.deflectionX)[j]
    var karambaValueY = JSON.parse(modeProps.deflectionY)[j]*-1
    var deflectionXScaled = karambaValueX*50
    var deflectionYScaled =  karambaValueY*50
    var deflectionPoint = new paper.Point(
      pointOnCurve.point.x+deflectionXScaled, 
      pointOnCurve.point.y-deflectionYScaled
    )
    path.add(deflectionPoint)
  }
  drawUtilization(element, elementProps, path){ 
    // Normalize utilization and clamp between 0 and 1
    let minUtilization = 0
    let maxUtilization = 1
    let interpolationFactor = (elementProps.utilization - minUtilization) / (maxUtilization - minUtilization);
    interpolationFactor = Math.max(0, Math.min(0.9999, interpolationFactor)); 

    // Calc interpolated color and set path stroke color
    let gradientColor = utils.interpolateColors(gradientColors, interpolationFactor);
    path.strokeColor = gradientColor

    path.data.utilization = elementProps.utilization

    //Get Text location
    element.segments.forEach(segment => path.add(segment.point))
    let midPoint = path.getPointAt(path.length/2)
    var [leftPoint, rightPoint] = utils.reorientLine(path)
    let tempLine = new paper.Path(leftPoint, rightPoint)
    let normal = tempLine.getNormalAt(0)
    let offsetPoint = midPoint.add({x: -normal.x * 15/this.store.grid.canvasZoom, y: -normal.y * 10/this.store.grid.canvasZoom})

    //Create Text
    let text = new paper.PointText(offsetPoint)
    let utilizationText = `${utils.Round(elementProps.utilization * 100, 2)}%`
    text.style = styles.analysisTextStyles.utilization
    text.content = utilizationText
    text.fillColor = gradientColor
    text.rotate(modifiers.textRotation(path), offsetPoint)
    text.scale(1/this.store.grid.canvasZoom)
    text.data.anchorPoint = midPoint
    text.data.normal = normal
    text.data.utilization = elementProps.utilization
    this.store.utilizationValuesLayer.addChild(text)
  }
  recolorUtilization(min, max){
    this.store.utilizationLayer.children.forEach(path => {
      let utilization = path.data.utilization
      if (utilization > 1){
        path.strokeColor = 'red'
      }
      else if (utilization < min || utilization > max){
        path.strokeColor = 'grey'
      } else {
        let interpolationFactor = (utilization - min) / (max - min)
        let gradientColor = utils.interpolateColors(gradientColors, interpolationFactor);
        path.strokeColor = gradientColor
      }
    })
    this.store.utilizationValuesLayer.children.forEach(text => {
      let utilization = text.data.utilization
      if (utilization > 1){
        text.fillColor = 'red'
      }
      else if (utilization < min || utilization > max){
        text.fillColor = 'grey'
      } else {
        let interpolationFactor = (utilization - min) / (max - min)
        let gradientColor = utils.interpolateColors(gradientColors, interpolationFactor);
        text.fillColor = gradientColor
      }
    })
  }
  addPointToPath(paths, pointOnCurve){
    for (const path in paths){
      if (!(path == 'deflection' || path == 'mode' || path == 'utilization')){
        paths[path].add(pointOnCurve.point)
      }
    }
  }
  getElemProps(elementId){
    for (var object in this.results.elements){
      if (this.results.elements[object].id == elementId){
        return this.results.elements[object]
      }
    }
  }
  redrawResult(resultType){
    var analysisLayer = this.store.analysisLayers[resultType]
    var analysisValuesLayer = this.store.analysisValuesLayers[resultType]
    if (analysisLayer.children.length > 0){
      analysisLayer.removeChildren()
      analysisValuesLayer.removeChildren()
    }
    
    var elements = this.store.drawingLayer.children
    for (let i = 0; i < elements.length; i++){
      var elementId = elements[i].id
      var elemProps = this.getElemProps(elementId)
      var relValues = this.getMinMaxValues(elemProps)
      var paths = {}
      for (const path in styles.analysisPaths){
        if (path.includes(resultType)){
          var style = styles.analysisPaths[path]
          paths[path] = new paper.Path(style)
          paths[path].strokeWidth = style.strokeWidth/this.store.grid.canvasZoom
        }
      }

      var [leftPoint, rightPoint] = utils.reorientLine(elements[i])
      var elementReoriented = new paper.Path([leftPoint, rightPoint])
      var location = 0
      var normal = elements[i].data.normal

      for (let j = 0; j<11; j++){
        var pointOnCurve = elementReoriented.getLocationAt(location*elementReoriented.length)
        if (j == 0 && resultType != 'deflection'){
          paths[resultType].add(pointOnCurve.point)
        }
        if (resultType == 'deflection'){
          this.drawdeflection(elemProps, j, paths.deflection, pointOnCurve, relValues)                
        }
        else {
          this.drawForce([resultType], elemProps, j, paths, pointOnCurve, normal, relValues)                
        }
        if (j== 10 && resultType != 'deflection'){
          paths[resultType].add(pointOnCurve.point)
        }
        location += 0.1
      }
      this.store.analysisLayers[resultType].addChild(paths[resultType])
    }
  }
  redrawModeShape(){
    var analysisLayer = this.store.analysisLayers['mode']
    if (analysisLayer.children.length > 0){
      analysisLayer.removeChildren()
    }
    var elements = this.store.drawingLayer.children
    var currentMode = this.results.modes[this.store.mode-1]
    this.store.frequency = currentMode.frequency
    this.store.period = currentMode.period
    for (let i = 0; i < elements.length; i++){
      var elementId = elements[i].id
      var modeProps = currentMode.elements.find(element => element.id == elementId)
      var pathStyle = styles.analysisPaths['mode']
      var path = new paper.Path(pathStyle)

      var [leftPoint, rightPoint] = utils.reorientLine(elements[i])
      var elementReoriented = new paper.Path([leftPoint, rightPoint])
      var location = 0

      for (let j = 0; j<11; j++){
        var pointOnCurve = elementReoriented.getLocationAt(location*elementReoriented.length)
        this.drawModeShape(modeProps, j, path, pointOnCurve)
        location += 0.1
      }
      path.smooth({type: 'continuous'})
      this.store.analysisLayers['mode'].addChild(path)
    }
  }
  okayToRun(){
    var runAnalysis = true
    var analysisMessage = ''

    let lines = this.store.drawingLayer.children
    let duplicates = false
    for (let i = 0; i < lines.length; i++) {
        for (let j = i + 1; j < lines.length; j++) {
            let line1 = lines[i];
            let line2 = lines[j];

            // Check if both lines have exactly 2 segments and are therefore straight lines
            if (line1.segments.length === 2 && line2.segments.length === 2) {
                let line1Start = line1.segments[0].point;
                let line1End = line1.segments[1].point;
                let line2Start = line2.segments[0].point;
                let line2End = line2.segments[1].point;

                // Check if the lines are identical or are the same line but start and end are reversed
                if ((utils.pointsSameLocation(line1Start, line2Start) && utils.pointsSameLocation(line1End, line2End)) ||
                    (utils.pointsSameLocation(line1Start, line2End) && utils.pointsSameLocation(line1End, line2Start))) {
                    duplicates = true
                    break
                }
            }
        }
    }
    if (duplicates == true) analysisMessage += 'Overlapping Frame Elements Not Allowed'

    if (this.store.supportLayer.children.length == 0){
      analysisMessage += 'Missing Supports'
    }
    if (this.store.drawingLayer.children.length == 0){
      if (analysisMessage == '') analysisMessage += 'Missing Frame Elements'
      else analysisMessage += ' And Frame Elements'
    }
    //Check if All Support Land On A Node
    for (const support of this.store.supportLayer.children) {
      if (!support.data.location) break 
      let supportHasNode = this.store.nodeLayer.children.some(node => 
        utils.pointsSameLocation(node.data.location, support.data.location)
      );
      if (!supportHasNode) {
        if (analysisMessage != '') analysisMessage += '<br>'
        analysisMessage += 'All Supports Must Land On Nodes';
        break; 
      }
    }
    //Check All Point Loads Land on a Node
    for (const load of this.store.loadLayer.children) {
      if (load.data.type == 'Point Load'){
        let pointLoadHasNode = this.store.nodeLayer.children.some(node => 
          utils.pointsSameLocation(node.data.location, load.data.location));
        if (!pointLoadHasNode) {
          if (analysisMessage != '') analysisMessage += '<br>'
          analysisMessage += 'All Point Loads Must Land On Nodes'
          break
        }
      }
    }
    if (analysisMessage != ''){
      runAnalysis = false
      analysisMessage += '<br> Analysis Not Run!'
      this.store.snackbarText = analysisMessage
      this.store.snackbar = true
    }   
    return runAnalysis
  }
  deactivate(){
    for (const layer in this.store.analysisLayers){
      this.store.analysisLayers[layer].removeChildren()
    }
    for (const layer in this.store.analysisValuesLayers){
      this.store.analysisValuesLayers[layer].removeChildren()
    }
    if (this.store.drawingLayer.visible == false){
      this.store.drawingLayer.visible = true
      this.store.nodeLayer.visible = true
    }
    this.store.reactionLayer.removeChildren()
    this.results = null
    this.origin = null
    this.warning = null
    this.finishedComputing = null
    this.maxValues = null
    this.scalars = null
  }
}
